.App {
  text-align: center;
  background-color: #f5f6f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #3d4449;
  margin: 20px 0;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
}