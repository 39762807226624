.booking-form {
  background: white;
  border-radius: 0.375em;
  padding: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
}

.booking-form label {
  display: block;
  margin-bottom: 1em;
  color: #3d4449;
}

.booking-form input,
.booking-form select {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5em;
  border: 1px solid rgba(210, 215, 217, 0.75);
  border-radius: 0.375em;
}

.booking-form button {
  background-color: #f56a6a;
  color: white;
  border: none;
  padding: 0.75em;
  border-radius: 0.375em;
  cursor: pointer;
  font-size: 1em;
}

.booking-form button:hover {
  background-color: #f67878;
}